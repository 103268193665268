import React from 'react'

import { Header, Footer } from '../'

import '../../assets/reset.scss'
import './Layout.scss'

const Layout = ({ children, pathPrefix, pathName }) => (
  <React.Fragment>
    <Header
      pathPrefix={ pathPrefix }
      pathName={ pathName }
    />
    { children }
    <Footer />
  </React.Fragment>
)

export default Layout
