import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import './FeaturedPost.scss'

const FeaturedPost = ({ post: { fields, frontmatter }, featuredIn = false, nextPost = false }) => (
  <section className='FeaturedPost'>
    <div className='FeaturedPostImage'>
      <Link
        to={ fields.slug }
        className={ nextPost ? 'NextPost ' : '' }
      >
        <Img 
          sizes={ frontmatter.featuredImage.childImageSharp.sizes } 
          alt={ frontmatter.featuredImageAltText }
        />
      </Link>
    </div>
    <div className='FeaturedPostInfo'>
      <p className='FeaturedPostCategory'>
        { featuredIn ? 'Featured in ' : 'Posted in ' }
        {
          frontmatter.category.map((category, index) => (
            <React.Fragment key={ category }>
              <Link
                className='FeaturedPostCategoryName'
                to={ `/categories/${category}` }
              >
                { category }
              </Link>
              <span>
                { index !== frontmatter.category.length - 1 && ', ' }
              </span>
            </React.Fragment>
          ))
        }
      </p>
      <h1 className='FeaturedPostTitle'>
        <Link
          to={ fields.slug }
          className={ nextPost ? 'NextPost ' : '' }
        >
          { frontmatter.title }
        </Link>
      </h1>
      <p className='FeaturedPostExcerpt'>
        { frontmatter.excerpt }
      </p>
      <p className='FeaturedPostAuthor'>
        { frontmatter.author.map((author, index) => (
          <React.Fragment key={ author.id }>
            { author.id }
            { index !== frontmatter.author.length - 1 && ', ' }
          </React.Fragment>
        )) }
        <span className='FeaturedPostReadingTime'>
          { ' | ' }
          { fields.readingTime.text }
        </span>
      </p>
    </div>
  </section>
)

export default FeaturedPost
