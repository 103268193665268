import React from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import './Footer.scss'

import logo from '../../assets/logo.svg'

const Footer = () => (
  <footer className='Footer'>
    <div className='PageContainer'>
      <h3 className='FooterHeading'>Work with us!</h3>
      <p className='FooterTagline'>Help us build the future of web application security in an amazing place to work, learn, and grow.</p>
      <OutboundLink
        href='https://www.signalsciences.com/careers/'
        className='FooterButton'
      >
        See job openings
      </OutboundLink>
      <hr />
      <nav className='FooterNav'>
        <div className='FooterNavMain'>
          <div className='FooterLogo'>
            <Link to='/'>
              <img src={ logo } alt='Behind the Firewall, by Signal Sciences' />
            </Link>
          </div>
          <StaticQuery
            query={ graphql`
              query FooterCategoriesQuery {
                allCategoryYaml(filter: { id: { ne: "home" } }) {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            ` }
            render={ data => (
              data.allCategoryYaml.edges.map(edge => (
                <Link
                  key={ edge.node.id }
                  to={ `/categories/${edge.node.id}` }
                  className='FooterMenu__Item'
                >
                  { edge.node.id }
                </Link>
              ))
            ) }
          />
        </div>
        <div>
          <OutboundLink
            href='https://signalsciences.com'
            className='FooterMenu__Item FooterMenu__Item--Website'
          >
            www.signalsciences.com
          </OutboundLink>
        </div>
      </nav>
    </div>
  </footer>
)

export default Footer
