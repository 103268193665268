import React from 'react'
import { Link } from 'gatsby'

import SocialLinks from '../SocialLinks'

import './PostMeta.scss'

const PostMeta = ({ post, mobileBefore, mobileAfter }) => {
  const readTimeText = post.fields.readingTime.text

  if (mobileBefore) {
    return (
      <div className='PostMeta--MobileCategoryShare'>
        <div>
          <small className='PostMetaLabel'>Posted in</small>
          <p>
            { post.frontmatter.category.map(category => (
              <Link
                key={ category }
                to={ `/categories/${category}` }
              >
                <span className='PostMetaCategory'>
                  { category }
                </span>
              </Link>
            )) }
          </p>
        </div>
        <div>
          <small className='PostMetaLabel'>Share</small>
          <SocialLinks post={ post } />
        </div>
      </div>
    )
  }

  if (mobileAfter) {
    return (
      <div className='PostMeta--MobileAuthorReadTime'>
        { post.frontmatter.author.map((author, index) => (
          <React.Fragment key={ author.id }>
            <span className='PostMeta--MobileAuthor'>
              { author.id },
              <span className="PostMetaAuthorBio"> { author.bio }</span>
            </span>
            { index !== post.frontmatter.category.length - 1 && <span>, </span> }
          </React.Fragment>
        )) }
        <br />
        <time>
          { post.frontmatter.date }
        </time>
        <span className='MiddleDot'>&middot;</span>
        <span>
          { readTimeText }
        </span>
      </div>
    )
  }

  return (
    <div className='PostMeta'>
      <small className='PostMetaLabel'>Share</small>
      <SocialLinks post={ post } />
      <small className='PostMetaLabel'>Reading Time</small>
      <div className='PostMetaValue'>
        { readTimeText.substr(0, readTimeText.length - 5) }
      </div>
      <small className='PostMetaLabel'>
        Author{ post.frontmatter.author.length > 1 && 's' }
      </small>
      { post.frontmatter.author.map(author => (
        <a
          key={ author.id }
          className='PostMetaAuthor PostMetaValue'
          href={ author.url }
        >
          { author.id }
          <br />
          <span className="PostMetaAuthorBio">{ author.bio }</span>
        </a>
      )) }
      <small className='PostMetaLabel'>Published on</small>
      <time className='PostMetaValue'>
        { post.frontmatter.date }
      </time>
      <small className='PostMetaLabel'>Posted in</small>
      <p>
        { post.frontmatter.category.map(category => (
          <Link
            key={ category }
            to={ `/categories/${category}` }
          >
            <span className='PostMetaCategory'>
              { category }
            </span>
          </Link>
        )) }
      </p>
    </div>
  )
}

export default PostMeta
