import React, { useState } from 'react'
import { Link, StaticQuery, graphql } from 'gatsby'
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import './Header.scss'

import logo from '../../assets/logo.svg'

const Header = ({ pathPrefix, pathName }) => {
  const [menuOpen, setMenuOpen] = useState(false)
  const categoryPath = process.env.NODE_ENV === 'development'
    ? '/categories/'
    : `${pathPrefix}/categories/`

  return (
    <header className={ menuOpen ? 'Header Header--MenuOpen' : 'Header' }>
      <div className='HeaderLogo'>
        <Link to='/'>
          <img
            src={ logo }
            alt='Behind the Firewall, by Signal Sciences'
          />
        </Link>
        <button
          className='HeaderMobileNavToggle'
          onClick={ () => setMenuOpen(!menuOpen) }
          type='button'
        >
          <div className='HeaderMobileNavToggleBar' />
          <div className='HeaderMobileNavToggleBar' />
          <div className='HeaderMobileNavToggleBar' />
        </button>
      </div>
      <div className='HeaderNavigation'>
        <nav>
          <StaticQuery
            query={ graphql`
              query HeaderCategoriesQuery {
                allCategoryYaml(filter: { id: { ne: "home" } }) {
                  edges {
                    node {
                      id
                    }
                  }
                }
              }
            ` }
            render={ data => (
              data.allCategoryYaml.edges.map(edge => (
                <Link
                  key={ edge.node.id }
                  to={ `/categories/${edge.node.id}` }
                  className={ pathName === `${categoryPath}${edge.node.id}`
                    ? 'HeaderMenu__Item HeaderMenu__Item--Active'
                    : 'HeaderMenu__Item'
                  }
                >
                  { edge.node.id }
                </Link>
              ))
            ) }
          />
        </nav>
        <OutboundLink
          href='https://www.signalsciences.com/careers/'
          className='HeaderHiring'
        >
          <span role='img' aria-label='Waving hand'>👋</span> We{ "'" }re hiring!
        </OutboundLink>
      </div>
    </header>
  )
}

export default Header
