import React from 'react'

import './FeaturedQuote.scss'

const FeaturedQuote = ({ quote }) => (
  <article className='FeaturedQuote'>
    <p>{ quote }</p>
  </article>
)

export default FeaturedQuote
