import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

import './GridItemPost.scss'

const GridItemPost = ({ defaultImage, post, showCategories = true }) => (
  <section className='GridItemPost'>
    <div>
      <Link to={ post.fields.slug }>
        <Img
          className='GridItemPostImage'
          sizes={ (post.frontmatter.featuredImage)
            ? post.frontmatter.featuredImage.childImageSharp.sizes
            : defaultImage.childImageSharp.sizes
          }
          alt={ post.frontmatter.featuredImageAltText }
        />
      </Link>
      <p className='GridItemPostMeta GridItemPostMetaCategory'>
        { showCategories && post.frontmatter.category.map((category, index) => (
          <React.Fragment key={ category }>
            <Link
              className='GridItemPostCategoryName'
              to={ `/categories/${category}` }
            >
              { category }
            </Link>
            { index !== post.frontmatter.category.length - 1 && ', ' }
          </React.Fragment>
        )) }
        <span className='GridItemPostReadTime'>{ post.fields.readingTime.text }</span>
      </p>
      <h2 className='GridItemPostTitle'>
        <Link to={ post.fields.slug }>{ post.frontmatter.title }</Link>
      </h2>
      <p className='GridItemPostExcerpt'>{ post.frontmatter.excerpt }</p>
    </div>
  </section>
)

export default GridItemPost
