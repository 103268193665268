import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import './SocialLinks.scss'

const SvgWrapper = ({ children, width, height }) => (
  <svg
    width={ width.toString() }
    height={ height.toString() }
    viewBox={ `0 0 ${width} ${height}` }
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    { children }
  </svg>
)

const SocialLinks = ({ post }) => (
  <StaticQuery
    query={ graphql`
      query SocialLinksQuery {
        site {
          siteMetadata {
            siteUrl
          }
          pathPrefix
        }
      }
    ` }
    render={ data => (
      <ul className='SocialLinks'>
        <li style={ { width: 24, height: 25 } }>
          <a
            href={ `https://www.facebook.com/sharer/sharer.php?u=${data.site.siteMetadata.siteUrl}${data.site.pathPrefix}${post.fields.slug}` }
            target='_blank'
            rel='noopener noreferrer'
            className='ShareFacebook'
          >
            <SvgWrapper width={ 24 } height={ 25 }>
              <path d='M22.676 0.453552H1.324C0.593 0.453552 0 1.05062 0 1.78663V23.285C0 24.0221 0.593 24.6181 1.324 24.6181H12.818V15.2604H9.689V11.6146H12.818V8.92122C12.818 5.80097 14.712 4.10341 17.477 4.10341C18.802 4.10341 19.941 4.20107 20.273 4.24538V7.50759H18.352C16.852 7.50759 16.56 8.23354 16.56 9.29074V11.6176H20.144L19.679 15.2725H16.56V24.6181H22.675C23.408 24.6181 24 24.0221 24 23.285V1.78663C24 1.05062 23.408 0.453552 22.676 0.453552Z' />
            </SvgWrapper>
          </a>
        </li>
        <li style={ { width: 25, height: 21 } }>
          <a
            href={ `https://twitter.com/intent/tweet/?text=${post.frontmatter.title}&url=${data.site.siteMetadata.siteUrl}${data.site.pathPrefix}${post.fields.slug}&via=signalsciences` }
            target='_blank'
            rel='noopener noreferrer'
            className='ShareTwitter'
          >
            <SvgWrapper width={ 25 } height={ 21 }>
              <path d='M23.954 3.05389C23.069 3.44556 22.124 3.71238 21.129 3.83421C22.143 3.21902 22.923 2.24941 23.292 1.09254C22.341 1.65134 21.287 2.05811 20.165 2.28465C19.269 1.31908 17.992 0.714966 16.574 0.714966C13.857 0.714966 11.654 2.93307 11.654 5.66568C11.654 6.05835 11.699 6.43592 11.781 6.79738C7.691 6.60306 4.066 4.6256 1.64 1.63624C1.213 2.36319 0.974 3.20794 0.974 4.12821C0.974 5.84993 1.844 7.36324 3.162 8.25229C2.355 8.22611 1.596 8.00259 0.934 7.63207V7.69349C0.934 10.0948 2.627 12.0975 4.88 12.5536C4.467 12.6653 4.031 12.7258 3.584 12.7258C3.27 12.7258 2.969 12.6955 2.668 12.6392C3.299 14.6056 5.113 16.0393 7.272 16.0796C5.592 17.4076 3.463 18.199 1.17 18.199C0.78 18.199 0.391 18.1759 0 18.1316C2.189 19.5351 4.768 20.3557 7.557 20.3557C16.611 20.3557 21.556 12.8083 21.556 6.27382C21.556 6.06339 21.556 5.85094 21.541 5.6395C22.502 4.94578 23.341 4.0688 24.001 3.07403L23.954 3.05389Z' />
            </SvgWrapper>
          </a>
        </li>
        <li style={ { width: 24, height: 25 } }>
          <a
            href={ `http://www.linkedin.com/shareArticle?mini=true&url=${data.site.siteMetadata.siteUrl}${data.site.pathPrefix}${post.fields.slug}` }
            target='_blank'
            rel='noopener noreferrer'
            className='ShareLinkedIn'
          >
            <SvgWrapper width={ 24 } height={ 25 }>
              <path d='M20.447 21.0458H16.893V15.4386C16.893 14.1015 16.866 12.3808 15.041 12.3808C13.188 12.3808 12.905 13.8357 12.905 15.3399V21.0458H9.351V9.51526H12.765V11.087H12.811C13.288 10.1808 14.448 9.22428 16.181 9.22428C19.782 9.22428 20.448 11.6105 20.448 14.7167V21.0458H20.447ZM5.337 7.93752C4.193 7.93752 3.274 7.00517 3.274 5.85836C3.274 4.71256 4.194 3.78121 5.337 3.78121C6.477 3.78121 7.401 4.71256 7.401 5.85836C7.401 7.00517 6.476 7.93752 5.337 7.93752ZM7.119 21.0458H3.555V9.51526H7.119V21.0458ZM22.225 0.453552H1.771C0.792 0.453552 0 1.23286 0 2.19441V22.8773C0 23.8398 0.792 24.6181 1.771 24.6181H22.222C23.2 24.6181 24 23.8398 24 22.8773V2.19441C24 1.23286 23.2 0.453552 22.222 0.453552H22.225Z' />
            </SvgWrapper>
          </a>
        </li>
      </ul>
    ) }
  />
)

export default SocialLinks
